import { AnimatePresence, motion } from "framer-motion";

import logo from "../../assets/images/logo-decoupe.webp";

const Loading = ({message}) => {
    return (
      <AnimatePresence exitBeforeEnter>
        <div className="loadingContainer">
          <motion.img
              src={logo}
              alt="logo Radamir"
              className="loadingLogo"
              id="logo"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transitions={{ transition: "linear", duration: 3 }}
          />
          <p className="loadingText">{message}</p>
        </div>
      </AnimatePresence>
  )
};

export default Loading;