import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import socketIOClient from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import MotionWrapper from "../MotionWrapper";
import ConfirmDelete from "../ConfirmDelete";
import EditCampaignModal from "../EditCampaignModal";
import Loading from "../Loading";
import AddNoteModal from "../AddNoteModal";

import {
  deleteCampaign,
  setCampaignInput,
  addPlayer,
  getAllUsers,
} from "../../actions/campaigns";
import { clearError } from "../../actions/error";

import {
  setTitle,
  setType,
  setContent,
  setImageUrl,
  fetchNotes,
} from "../../actions/notes";

import { removePlayer } from "../../actions/campaigns";

import carte from "../../assets/images/CarteRadamir.png";
import bg2 from "../../assets/images/bg2.png";

import "./campaign.scss";

import Note from "./Note";
import ConfirmModal from "./confirmModal/confirmModal";
import LogoLoading from "../Loading";

const Campaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { id } = useParams();
  const userId = useSelector(({ user: { loggedUser } }) => loggedUser.id);
  const { loading } = useSelector(({ notes }) => notes);
  const campaignLoader = useSelector(({ campaigns }) => campaigns.loading);
  const { addUserList, playersList, list } = useSelector(
    ({ campaigns }) => campaigns
  );

  const currentCampaign = list.find((campaign) => campaign.id === +id);

  const [imgUrl, setImgUrl] = useState(
    "https://cdn.discordapp.com/attachments/837830452042661899/897226129709096960/Cennetig_le_Minutieux.jpg"
  );

  const ENDPOINT = process.env.REACT_APP_SERVERIO;
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, {
      reconnection: false,
    });
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on("ImgUrl", (data) => {
      setImgUrl(data);
    });
    return function cleanup() {
      console.log("disconnecting socket");
      socket.disconnect();
    };
  }, [ENDPOINT]);

  useEffect(() => {
    dispatch(getAllUsers(id, userId));
  }, [dispatch, id, userId]);

  const [selectedUser, setSelectedUser] = useState("");

  const handleDelete = () => {
    dispatch(deleteCampaign(userId, +id));
    history.push("/campagnes");
  };

  const handleOpenEdit = () => {
    dispatch(setCampaignInput("campaign_name", currentCampaign.campaign_name));
    dispatch(setCampaignInput("description", currentCampaign.description));
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    dispatch(clearError());
    dispatch(setCampaignInput("campaign_name", ""));
    dispatch(setCampaignInput("description", ""));
    setEditOpen(false);
  };

  const addModalFromImage = () => {
    dispatch(setImageUrl(imgUrl));
    openAddModal();
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };
  const onAddModalClose = () => {
    setIsAddModalOpen(false);
    dispatch(setType("publique"));
    dispatch(setTitle(""));
    dispatch(setContent(""));
    dispatch(clearError());
  };

  useEffect(() => {
    dispatch(fetchNotes(id, userId));
  }, [dispatch, id, userId]);

  const notesList = useSelector(({ notes }) => notes.list);

  const [confirmMessage, setConfirmMessage] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [removingPlayerId, setRemovingPlayerId] = useState();

  const openConfirmModal = async (player_id) => {
    console.log(player_id);
    setRemovingPlayerId(player_id);
    console.log(removingPlayerId);
    playersList.length === 1
      ? setConfirmMessage(
          "En retirant le dernier joueur lié à cette campagne, vous la supprimerez par la même occasion. Continuer ?"
        )
      : setConfirmMessage(
          "Voulez-vous vraiment vous retirer de cette campagne ? Vous ne pourrez pas y revenir de vous-même."
        );
    setIsConfirmModalOpen(true);
  };

  const deletePlayer = async () => {
    await dispatch(getAllUsers(id, userId));
    setIsConfirmModalOpen(false);
    if (playersList.length < 2) {
      dispatch(deleteCampaign(userId, +id));
      history.push("/campagnes");
      console.log("delete campaign");
    } else {
      dispatch(removePlayer(removingPlayerId, id, userId));
      setRemovingPlayerId("");
      console.log("delete player");
    }
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  if (campaignLoader) {
    return <Loading />;
  }

  const is_author = userId === currentCampaign.user_id;

  return (
    <MotionWrapper>
      <div className="campaign">
        <h1>{currentCampaign.campaign_name}</h1>
        <section className="pageOne">
          <section className="sectionCarte">
            <img src={carte} alt="" />
          </section>
          <section className="sectionResume">
            <h2>Résumé de la campagne :</h2>
            <p>{currentCampaign.description}</p>
            {is_author && (
              <>
                <button
                  type="button"
                  className="admin-button"
                  onClick={handleOpenEdit}
                >
                  Modifier Campagne
                </button>
                <EditCampaignModal
                  open={editOpen}
                  onClose={handleCloseEdit}
                  campaignId={+id}
                />
                <button
                  type="button"
                  className="admin-button delete-wiki"
                  onClick={() => setDeleteOpen(true)}
                >
                  Supprimer Campagne
                </button>
                <ConfirmDelete
                  open={deleteOpen}
                  title={currentCampaign.campaign_name}
                  onClose={() => setDeleteOpen(false)}
                  onDelete={handleDelete}
                />
                <select
                  className="admin-button noHover"
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  <option value="">Ajouter des joueurs</option>
                  {addUserList.map((user) => {
                    return (
                      <option value={user.id} key={user.id}>
                        {user.username}
                      </option>
                    );
                  })}
                </select>
                {selectedUser !== "" && (
                  <button
                    className="admin-button"
                    onClick={() => {
                      dispatch(addPlayer(selectedUser, id, userId));
                    }}
                  >
                    Ajouter
                  </button>
                )}
              </>
            )}
            <div className="playersDiv">
              <h3>Joueurs:</h3>
              <div className="playerCards">
                {id == 2 ? (
                  <div className="admin-button characterCard">
                    Tout le monde !
                  </div>
                ) : (
                  playersList.map((player) => {
                    return (
                      <div
                        className="admin-button characterCard"
                        key={player.id}
                      >
                        <p className="characterName">{player.username}</p>
                        {(is_author || player.id === userId) && (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="deleteCard"
                            onClick={() => openConfirmModal(player.id)}
                          />
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <ConfirmModal
              message={confirmMessage}
              open={isConfirmModalOpen}
              onClose={closeConfirmModal}
              onConfirm={deletePlayer}
            />
          </section>
        </section>
        <section className="pageTwo">
          <div className="bgContainer">
            <img src={bg2} alt="" className="bg2" />
          </div>
          {loading && <LogoLoading message="Chargement des notes..." />}
          {!loading && (
          <>
            <h2>Notes</h2>
            <section className="allNotes">
              <section className="notesPrivees">
                <h3>Mes notes privées</h3>
                {notesList.myPrivates.map((note) => (
                  <Note
                    title={note.title}
                    content={note.content}
                    note_id={note.id}
                    creator_id={note.user_id}
                    campaign_id={id}
                    user_id={userId}
                    is_private={note.is_private}
                    image_url={note.image_url}
                    key={note.id}
                  />
                ))}
              </section>
              <section className="notesPubliques">
                <h3>Notes du groupe</h3>
                {notesList.myPublics.map((note) => (
                  <Note
                    title={note.title}
                    content={note.content}
                    note_id={note.id}
                    creator_id={note.user_id}
                    campaign_id={id}
                    user_id={userId}
                    is_private={note.is_private}
                    image_url={note.image_url}
                    key={note.id}
                  />
                ))}
                {notesList.publics.map((note) => (
                  <Note
                    title={note.title}
                    content={note.content}
                    note_id={note.id}
                    creator_id={note.user_id}
                    campaign_id={id}
                    user_id={userId}
                    is_private={note.is_private}
                    image_url={note.image_url}
                    key={note.id}
                  />
                ))}
              </section>
              <section className="imageDiscord">
                <h3>Illustration</h3>
                <div className="imgContainer">
                  <img
                    src={imgUrl}
                    alt=""
                    className="discordImg"
                    onClick={addModalFromImage}
                  />
                </div>
              </section>
            </section>
            <button className="addNote" onClick={openAddModal}>
              Ajouter une nouvelle note
            </button>
            <AddNoteModal
              open={isAddModalOpen}
              onClose={onAddModalClose}
              campaign_id={id}
            />
          </>
          )}
        </section>
      </div>
    </MotionWrapper>
  );
};

export default Campaign;
