import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ConfirmModal = ({ validate, message, onClose, open, onConfirm }) => {
  if (!open) return null;

  return createPortal(
    <>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="noteModalContainer">
            <h2>Confirmation</h2>
            <FontAwesomeIcon icon={faTimes} className="closeIcon" onClick={onClose} />
            <div className="contentContainer">
                <p className="confirmMessage">{message}</p>
            </div>
            <div className="confirmButtons">
                <button className="admin-button" onClick={onConfirm}>Confirmer</button>
                <button className="admin-button" onClick={onClose}>Annuler</button>
            </div>
        </div>
    </>,
    document.querySelector("#modal")
  );
};

export default ConfirmModal;
