import { OPEN_LOGIN, CLOSE_LOGIN, TOGGLE_LOGIN } from "../actions/login";

const initialState = {
  logOpen: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return {
        logOpen: true,
      };

    case CLOSE_LOGIN:
      return {
        logOpen: false,
      };

    case TOGGLE_LOGIN:
      return {
        logOpen: !state.logOpen,
      };

    default:
      return state;
  }
};

export default reducer;
