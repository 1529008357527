import radamirAPI from "../apis/radamirAPI";
import {
  FETCH_CAMPAIGNS,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  setCampaignLoading,
  saveCampaigns,
  fetchCampaigns,
  ADD_PLAYER,
  GET_ALL_USERS,
  saveUsers,
  savePlayers,
  getAllUsers,
  REMOVE_PLAYER,
} from "../actions/campaigns";
import { clearError, setError } from "../actions/error";

const campaignsMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case FETCH_CAMPAIGNS:
      try {
        const res = await radamirAPI.get("/campaigns", {
          params: {
            user_id: action.user_id,
          },
        });

        const userCampaigns = res.data;

        store.dispatch(saveCampaigns(userCampaigns));
      } catch (err) {
        console.error(err);
      }
      next(action);
      break;

    case GET_ALL_USERS:
      try {
        const res = await radamirAPI.get(
          `/user/all/${action.user_id}/${action.campaign_id}`
        );
        const allUsers = res.data;
        store.dispatch(saveUsers(allUsers.final_data));
        store.dispatch(savePlayers(allUsers.players));
      } catch (err) {
        console.error(err);
      }
      next(action);
      break;

    case CREATE_CAMPAIGN:
      try {
        store.dispatch(setCampaignLoading());
        store.dispatch(clearError());
        const { campaign_name, description } = store.getState().campaigns;

        if (!campaign_name || !description)
          throw new Error("Veuillez renseigner des informations");

        await radamirAPI.post("/campaigns", {
          user_id: action.id,
          campaign_name,
          description,
        });

        store.dispatch(fetchCampaigns(action.id));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(err.message));
      }
      next(action);
      break;

    case UPDATE_CAMPAIGN:
      try {
        store.dispatch(clearError());
        const { campaign_name, description } = store.getState().campaigns;

        if (!campaign_name || !description)
          throw new Error("Veuillez renseigner des informations");

        await radamirAPI.patch(`/campaigns/${action.campaignId}`, {
          campaign_name,
          description,
        });

        store.dispatch(fetchCampaigns(action.userId));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(err.message));
      }
      next(action);
      break;

    case ADD_PLAYER:
      try {
        await radamirAPI.post("campaigns/add", {
          player_id: action.userId,
          campaign_id: action.campaignId,
        });
        store.dispatch(getAllUsers(action.campaignId, action.loggedUserId));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(err.message));
      }
      next(action);
      break;

    case REMOVE_PLAYER:
      try {
        await radamirAPI.delete(
          `/campaign/remove-player/${action.player_id}/${action.campaign_id}`
        );
        store.dispatch(getAllUsers(action.campaign_id, action.user_id));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(err.message));
      }
      next(action);
      break;

    case DELETE_CAMPAIGN:
      try {
        await radamirAPI.delete(`/campaigns/${action.campaignId}`);

        store.dispatch(fetchCampaigns(action.userId));
      } catch (err) {
        console.error(err);
      }
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default campaignsMiddleware;
