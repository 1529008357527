export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const fetchCampaigns = (user_id) => ({
  type: FETCH_CAMPAIGNS,
  user_id
});

export const SAVE_CAMPAIGNS = "SAVE_CAMPAIGNS";
export const saveCampaigns = (campaigns) => ({
  type: SAVE_CAMPAIGNS,
  campaigns
});

export const SET_CAMPAIGN_INPUT = "SET_CAMPAIGN_INPUT";
export const setCampaignInput = (fieldname, value) => ({
  type: SET_CAMPAIGN_INPUT,
  value,
  fieldname
});

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const createCampaign = (id) => ({
  type: CREATE_CAMPAIGN,
  id
});

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const updateCampaign = (userId, campaignId) => ({
  type: UPDATE_CAMPAIGN,
  userId,
  campaignId
});

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const deleteCampaign = (userId, campaignId) => ({
  type: DELETE_CAMPAIGN,
  userId,
  campaignId
});

export const SET_CAMPAIGN_LOADING = "SET_CAMPAIGN_LOADING";
export const setCampaignLoading = () => ({
  type: SET_CAMPAIGN_LOADING
});

export const ADD_PLAYER = "ADD_PLAYER";
export const addPlayer = (userId, campaignId, loggedUserId) => ({
  type: ADD_PLAYER,
  userId,
  campaignId,
  loggedUserId
})

export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const removePlayer = (player_id, campaign_id, user_id) => ({
  type: REMOVE_PLAYER,
  player_id,
  campaign_id,
  user_id
})

export const GET_ALL_USERS = "GET_ALL_USERS";
export const getAllUsers = (campaign_id, user_id) => ({
  type: GET_ALL_USERS,
  campaign_id,
  user_id
})

export const SAVE_USERS = "SAVE_USERS";
export const saveUsers = (users) => ({
  type: SAVE_USERS,
  users
})

export const SAVE_PLAYERS = "SAVE_PLAYERS";
export const savePlayers = (players) => ({
  type: SAVE_PLAYERS,
  players
})