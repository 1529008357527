import { useDispatch, useSelector } from "react-redux";

import FormField from "../FormField";
import MotionWrapper from "../MotionWrapper";

import { signUp } from "../../actions/user";
import { clearError, setError } from "../../actions/error";
import { openLogin } from "../../actions/login";

import "./signup.scss";
import { useEffect } from "react";

const SignUp = ({errorMessage}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearError());
    if(errorMessage){
      dispatch(setError(errorMessage));
    };
  }, [dispatch, errorMessage]);
  const { message } = useSelector(({ error }) => error);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signUp());
  };

  const login = () => {
    dispatch(openLogin());
    dispatch(clearError());
  }

  return (
    <MotionWrapper>
      <div className="signup">
        <div className="signup-container">
          <h1>Inscription</h1>
          {message && (
            <div className="error">
              {message}
            </div>
          )}
          <form className="signup__form" autoComplete="off" onSubmit={handleSubmit}>
            <FormField inputId="username" label="Identifiant" />
            <FormField inputId="email" label="Adresse e-mail" type="email" />
            <FormField inputId="password" label="Mot de passe" type="password" />
            <FormField inputId="passwordConfirm" label="Confirmer mot de passe" type="password" />
            <button type="submit" className="signButton">S'inscrire</button>
            <h3>Déjà membre ?</h3>
            <button type="button" className="signButton" onClick={login}>Se connecter</button>
          </form>
        </div>
      </div>
    </MotionWrapper>
  );
};

export default SignUp;