export const OPEN_LOGIN = "OPEN_LOGIN";
export const openLogin = () => ({
    type: OPEN_LOGIN
});

export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const closeLogin = () => ({
    type: CLOSE_LOGIN
});

export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const toggleLogin = () => ({
    type: TOGGLE_LOGIN
})