import {
  SAVE_CAMPAIGNS,
  SET_CAMPAIGN_INPUT,
  SET_CAMPAIGN_LOADING,
  SAVE_USERS,
  SAVE_PLAYERS,
} from "../actions/campaigns";
import { LOGOUT } from "../actions/user";

const initialState = {
  loading: true,
  list: [],
  campaign_name: "",
  description: "",
  addUserList: [],
  playersList: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CAMPAIGN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SAVE_CAMPAIGNS:
      return {
        ...state,
        list: action.campaigns,
        loading: false,
      };

    case SAVE_USERS:
      return {
        ...state,
        addUserList: action.users,
      };

    case SAVE_PLAYERS:
      return {
        ...state,
        playersList: action.players,
      };

    case SET_CAMPAIGN_INPUT:
      return {
        ...state,
        [action.fieldname]: action.value,
      };

    case LOGOUT:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default reducer;
